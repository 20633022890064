import { ServicePaymentDto } from '@wix/bookings-uou-types/dist/es/src';

export class PaymentDtoMapper {
  constructor(private regionalSettings: string) {}

  public priceText(paymentDto: ServicePaymentDto): string {
    const { isFree, priceText, currency, price } = paymentDto;
    const shouldShowPrice = !isFree && price;

    if (priceText) {
      return priceText;
    }

    if (shouldShowPrice) {
      const maximumFractionDigits = price % 1 === 0 ? 0 : 2;
      return new Intl.NumberFormat(this.regionalSettings, {
        style: 'currency',
        currency,
        maximumFractionDigits,
        minimumFractionDigits: 0,
      }).format(price);
    }

    return '';
  }
}
