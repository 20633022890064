import {
  ServiceLocation,
  ServiceLocationType,
} from '@wix/bookings-uou-types/dist/src';

export class LocationMapper {
  public text({
    serviceLocation,
    clientLocationText,
  }: {
    serviceLocation: ServiceLocation;
    clientLocationText: string;
  }): string {
    switch (serviceLocation.type) {
      case ServiceLocationType.OWNER_CUSTOM:
        return serviceLocation.address as string;
      case ServiceLocationType.CLIENT_PLACE:
        return clientLocationText;
      default:
        return '';
    }
  }
}
