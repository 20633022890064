import { ContactSectionViewModel } from '../contact-section-view-model/contactSectionViewModel';

export class ContactSectionViewModelBuilder {
  viewModel: ContactSectionViewModel = {};

  withPhone(phone: string) {
    this.viewModel.phone = phone;
    return this;
  }
  withEmail(email: string) {
    this.viewModel.email = email;
    return this;
  }
  withAddress(address: string) {
    this.viewModel.address = address;
    return this;
  }
  build(): ContactSectionViewModel {
    return this.viewModel;
  }
}
