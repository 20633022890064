import { BusinessInfo } from '@wix/bookings-uou-types/dist/src';

export interface ContactSectionViewModel {
  phone?: string;
  email?: string;
  address?: string;
}

export const contactSectionViewModelFactory = (
  businessInfo: BusinessInfo,
): ContactSectionViewModel => {
  return {
    phone: businessInfo?.phone,
    email: businessInfo?.email,
    address: businessInfo?.address,
  };
};
